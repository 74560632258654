import React from "react"

import { Link } from "gatsby"

import HeroImage from "../assets/Rectangle 4.png"
import Layout from "../components/layout"
import { OtherSolutions } from "../components/other-solution"
import SEO from "../components/seo"
import { WhyChooseUs } from "./what-we-do"

const HeroSection = () => (
  <section className="shadow relative">
    <div className="flex flex-col-reverse md:flex-row md:px-12 container md:py-32">
      <section data-aos="fade-right" className="md:w-7/12 px-5">
        <h1 className="text-4xl md:text-5xl font-black leading-tight">
          Your business processes
          <br />{" "}
          <span className="block text-pgray text-4xl md:text-5xl font-hairline">
            Automated.
          </span>
        </h1>
        <p className="text-pgray2 text-sm mt-4">
          We develop and implement custom software solutions tailored to your
          business needs, as well as focus on automating the existing workflows
          and facilitating data management.
          <br />
          From research to vision to implementation, Touchcore translates bold
          ideas into industry-leading software, mobil, and web applications that
          advances your business.
        </p>
        <div className="mt-8 flex flex-col md:flex-row items-start md:items-center">
          <button className="explore-button focus:outline-none">
            Explore Now
          </button>
          <Link
            to={"/our-company"}
            className="mt-8 md:mt-0 px-2 md:px-8 text-pgray"
          >
            Learn about our firm
          </Link>
        </div>
      </section>
      <img
        data-aos="fade-left"
        className="md:absolute right-0 top-0 md:w-1/2 md:h-full pb-20"
        src={HeroImage}
        alt={""}
      />
    </div>
  </section>
)
const OurSolutions = () => (
  <Layout tcoreinsight={false}>
    <SEO title="Our Solutions" />
    <HeroSection />
    <OtherSolutions />
    <WhyChooseUs />
  </Layout>
)
export default OurSolutions
